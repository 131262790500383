@tailwind base;
@tailwind components;
@tailwind utilities;

.or-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
}

.or-text {
  font-size: 12px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 34px;
  height: 24px;
  background: white;
  display: flex;
  align-tracks: center;
  justify-content: center;
  top: 2px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.pink-circle {
  background-color: rgba(246, 69, 154, 0.12);
}
.purple-circle {
  background-color: rgba(83, 69, 246, 0.12);
}
.blue-circle {
  background-color: rgba(77, 207, 236, 0.16);
}

.social-auth {
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.social-auth:hover {
  background-color: #f7f7f7;
}

.org-list {
  flex: 1 1;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
}

.org-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.org-list .org-item:last-of-type {
  border-bottom: none;
}

.setup-pages-header {
  background: #ffffff;
  box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.08);
  height: 64px;
}
